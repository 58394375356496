import NextImage from 'next/image';
import { Trans, useTranslation } from 'next-i18next';

import MotionContainer from '@/components/main/MotionContainer';
import proChartImageDark from '@/images/home-illust-pro-chart-dark.png';

import cs from './common.module.css';

export default function MainProChartSection() {
  const { t: th } = useTranslation('homeV2');

  return (
    <section className="bg-gray-950 overflow-hidden">
      <div className={cs.wrapper}>
        <MotionContainer className={cs.content}>
          <h3 className={cs.summary}>{th('Pro Chart')}</h3>

          <h2 className={cs.title}>
            <Trans t={th} i18nKey="main_pro_chart_summary_title" />
          </h2>
          <p className={cs.description}>
            <Trans t={th} i18nKey="main_pro_chart_summary_description" />
          </p>
        </MotionContainer>

        <MotionContainer className={cs['section-image']}>
          <div>
            <NextImage src={proChartImageDark} placeholder="blur" draggable="false" />
          </div>
        </MotionContainer>
      </div>
    </section>
  );
}
